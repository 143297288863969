<template>
  <div>
     <div id="text-display-wrapper">
      <canvas id="text-display" width="720" height="400"></canvas>
    </div>

    <form>
      <textarea v-model="text" type="text" id="textBox" name="name"> </textarea>
    </form>

    <div class="text-selectors">
      <div class="box-options-container">
        <p class="options-title">BORDER OPTIONS</p>
        <div class="box-options">
          <div class="box-selectors">
            <div class="slider-container">
              <label for="borderWidth" class="label-text"> Width</label>
              <input v-model="borderWidth" type="range" name="head" min="1" max="20" step="1">
            </div>
            <div class="colour-container">
              <label for="bordercolour" class="label-text"> Border Colour</label>
              <input v-model="borderColor" type="color" name="head" value="#e66465">
            </div>
          </div>
          <span class="number-display">{{borderWidth}} </span>
        </div>
      </div>

      <div class="box-options-container">
        <p class="options-title">TEXT OPTIONS</p>
        <div class="box-options">
          <div class="box-selectors">
            <div class="slider-container">
              <label for="textSize" class="label-text"> Text Size</label>
              <input v-model="textSize" type="range" name="head" min="0.3" max="1" step="0.1">
            </div>
            <div class="colour-container">
              <label for="fillcolour" class="label-text"> Fill Colour</label>
              <input v-model="fillColor" type="color" name="head" value="#e66465">
            </div>
          </div>
          <span class="number-display">{{textSize * 10}} </span>
        </div>
      </div>

    </div>
    
  </div>
</template>


<script>
import initialise, {draw} from '@/gameFiles/textCanvas.js';

export default {
    name: 'TextCanvas',
    data() {
      return {
        text: "Type here",
        fillColor: "#235450",
        borderColor: '#FFFFFF',
        textSize: 0.4,
        borderWidth: 5,
        textSizeDisplay: 0.4,
      };
    },
    watch: {
      text(newText) {
        draw({text: newText})
      },
      fillColor(newFill) {
        draw({fill: newFill})
      },
      borderColor(newBorderColor) {
        draw({borderColour: newBorderColor})
      },
      textSize(newTextSize) {
        draw({size: newTextSize})
      },
      borderWidth(newWidth) {
        draw({border: newWidth})
      },
    },
    mounted() {
        initialise();
        draw({text: this.text})
    },
    created() {
      window.addEventListener("resize", draw);
    },
    destroyed() {
      window.removeEventListener("resize", draw);
    },
}

</script>

<style type="text/css" scoped>

  #text-display-wrapper canvas { 
    position: absolute;
    top: 0;
    left: 0;

  }

  #text-display-wrapper  {
    background: #dcd3a2;
    position: relative;
    max-width: 720px;
    height: 400px;
    width: 100%;
    border: 1px solid black; 
  }

  #textBox {
    margin-top: 10px;
    width: 100%;
    max-width: 720px;
  }


  .text-options {
    width: 100%;
  }

  .options-title {
    margin-bottom: 5px;
    background: #ffdc14;
    border: 3px solid black;
    width: 100%;
    text-align: center;
    border-radius: 6px;
  }

  .text-selectors {
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    max-width: 720px;
    justify-content: space-evenly;
  }

  .label-text {
     padding: 0px 8px;
  }

  .box-options-container {
    margin: 0px 20px;
    user-select: none;
  }

  .box-title {
    background: #ffdc14;
    margin-bottom: 10px;
    border: 3px solid black;
    width: 100%;
    text-align: center;
    border-radius: 6px;
  }

  .box-options {
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    min-width: 285px;
    justify-content: space-between;
  }

  .box-selectors {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .number-display {
    display: flex;
    font-size: 45px;
    /* width: 100%;
    max-width: 75px;
    height: 65px; */
    padding: 0px 10px;
    border: 0px;
    text-align: center;
    justify-content: center;
  }

</style>

