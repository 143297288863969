<template>
<div>
    <ProjectPageTemplate
        projectTitle="TEXT"
        :imageSource="require('@/assets/images/projectLinks/textBoxLink.png')"
        shortDescription="Type in the text box and use HTML inputs to add text and alter how its displayed. These options alter values in the JavaScript and any changes trigger the canvas to be redrawn. Word length is calculated to check if it would make the current line show outside of the box. If this is the case then it is moved to a new line."
        longDescription= "All values relating to the text box are stored in a single JavaScript object. Vue watchers are used to check if values in the options have changed. If that’s the case it updates the object and then redraws the canvas. This has the advantage of not needing to remove event listeners when the user leaves the page. The HMTL input options are binded with the text box values using v-model.<br>
                        <br>Each letter width is stored in an array. When drawing, the combined width of the letters in a word are calculated as is the current line width. If adding the word to line would mean it extended beyond the width of the text box then the word is then drawn on the next line.  Letters images are stored as a single sprite sheet that and each letter is then cut out when drawn."
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
        <TextCanvas />
    </template>
    </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import TextCanvas from '@/components/TextCanvas.vue';
import techLinks from '@/data/techLinks.js'
export default {
    name: 'textCanvasPage',
    components: {
        ProjectPageTemplate,
        TextCanvas,
    },
    data() {
        return {
            techLinksData: [
                techLinks.html, 
                techLinks.javascript,
                techLinks.css
            ],
        }
    }
}
</script>