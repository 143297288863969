export function draw(options = {}) {
    resizeCanvas();
    if (options.text) {
        textBox.text = options.text.toUpperCase();
    }
    
    textBox.fill = options.fill ?? textBox.fill;
    textBox.borderColour = options.borderColour ?? textBox.borderColour;
    textBox.size = options.size ?? textBox.size;
    textBox.border = options.border ?? textBox.border;
    getTextLayout(textBox);
    drawTextBox(textBox);
}

function resizeCanvas() {
    let wrapper = document.getElementById('text-display-wrapper');
    canvas.width = wrapper.offsetWidth;
    textBox.xEnd = wrapper.offsetWidth - 40;
}

function completeUrl(urlList) {
    if (urlList === null) return;
    return urlList.map(URL => require('@/assets/images/textBoxes/' + URL)) ;
}

function loadImages() {
    let fullImageUrls = [...completeUrl(imageURL)];
    fullImageUrls.forEach(function(src) { 
        const image = new Image();
        image.src = src;
        image.onload = function() { 
            imageCount += 1;
            if(imageCount === fullImageUrls.length){ 
                draw()
            }
        }
        images.push(image); 

    });
}

function getTextLayout(box) {
    let yS = 60; // Height of letter in sprite sheet
    let yPosLetter = box.yTopCorner; //Sets the y position of a letter
    let letterNum = 0; // Number of letter in alphabet
    let wordWidth = 0; //Running count of the pixel width each word
    let letterWidth = 0; // Pixel width of the letter
    let currentLineWidth = 0; // Pixel width of current line
    const lineWidth = box.xEnd - box.xTopCorner; //Gets the width of the box
    let wordList = {
        number: [],
        width: [],
        xPosition:[],
    }
    let i2 = 0;
    for (let i = 0; i < box.text.length; i++) { // Repeat loop for every character in the string
        letterNum = letters.indexOf(box.text.charAt(i));
        letterWidth = letterWidths[letterNum] // Set letterWidth to letter number in array
        wordList.number.push(letterNum); //Add letter number to wordList number array
        wordList.xPosition.push(wordWidth * box.size); // Add the letter x position(word width) to wordList width 
        wordList.width.push(letterWidth);
        wordWidth += Math.ceil(letterWidth + 1) // Adds letter width to word width with letter spacing for refence in next letter
        
        if (box.text.charAt(i) === ' ' || i === box.text.length - 1) { //Checks if current character is a space or end of text to indicate end of word
            i2 = 0;
            if (currentLineWidth + (wordWidth * box.size) > lineWidth) { // If adding the word would mean text went beyond the line width..
                yPosLetter += yS * box.size; //Move to yPos down one line
                currentLineWidth = wordWidth * box.size; // Reset current line width
                while (i2 < wordList.number.length) {
                    box.letterList.push({number: wordList.number[i2], width: wordList.width[i2], xPos: wordList.xPosition[i2] + box.xTopCorner, yPos: yPosLetter});
                    i2++;
                }
            } else { // If the word width does not make the line exceed the box width..
                while (i2 < wordList.number.length) {
                    box.letterList.push({number: wordList.number[i2], width: wordList.width[i2], xPos: wordList.xPosition[i2] + (currentLineWidth + box.xTopCorner), yPos: yPosLetter});
                    i2++;
                }
                currentLineWidth += wordWidth * box.size; // If still within current line then set for x position reference of next word
            }
            wordList = { // Reset the letter values
                number: [],
                width: [],
                xPosition: [],
            }
            wordWidth = 0;
        }
        box.height = yPosLetter + yS;
    }
}

function drawTextBox(box) {
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    let yS = 60;
    ctx.save();
    drawTextBoxOutline(box);
    box.letterList.forEach(function(letter) {
        ctx.drawImage(
            images[0],
            Math.floor(letter.number / 26) * yS,
            (letter.number % 26) * yS,
            letter.width, 
            yS,
            letter.xPos,
            letter.yPos,
            letter.width * box.size,
            yS * box.size
        );
        box.letterList = [];
    });
    ctx.restore();
}

function drawTextBoxOutline(tBox) {
    let pd = tBox.padding
    ctx.lineWidth = tBox.border;
    if (tBox.fill) {
        ctx.fillStyle = tBox.fill;
        ctx.fillRect(tBox.xTopCorner - pd, tBox.yTopCorner - pd, (tBox.xEnd - tBox.xTopCorner) + (pd * 2), tBox.height + (pd * 2));
    }
    if (tBox.border) {
        ctx.strokeStyle = tBox.borderColour;
        ctx.strokeRect(tBox.xTopCorner - pd, tBox.yTopCorner - pd, (tBox.xEnd - tBox.xTopCorner) + (pd * 2), tBox.height + (pd * 2));
    }
}


let ctx;
let canvasWidth = 720;
let canvasHeight = 400;
let canvas;
let textBox = {};
const imageURL = ["newText.png"];
const images = []; 
let imageCount = 0;
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ -+/*_#=():"&!%1234567890';
const letterWidths = [
    47,40,42,44,35,35,45,41,27,28,43,34,48,43,48,38,47,44,41,40,42,46,54,44,44,39,
    25,23,42,32,33,47,35,41,20,22,12,28,51,15,59,33,37,37,40,37,39,37,38,39,39
];


export default function () {
    canvas = document.getElementById('text-display');
    ctx = canvas.getContext('2d');
    textBox = {
        xTopCorner: 50,
        yTopCorner: 25,
        xEnd: 600,
        height: 0,
        size: 0.4,
        padding: 15,
        border: 5,
        borderColour: '#FFFFFF',
        fill: '#235450',
        text: '',
        letterList: [],
    };
    loadImages();
}
